





import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";
import SelTimeType from './indexType';
import moment from "moment";

@Component({name: "SelTime"})
export default class SelTime extends Vue implements SelTimeType{
    show = false

    onConfirm(e=[]){
        let data:any = { startDate:"",endDate:"" }
        Array.isArray(e) && e.forEach((item,index)=>{
            if ( index ){
                data.endDate = moment(item).format("YYYY-MM-DD")
            }else{
                data.startDate = moment(item).format("YYYY-MM-DD")
            }
        })
        this.pullConFirm(data)
        this.pullClose()
    }

    @Emit("pullConFirm")
    pullConFirm(data:any){
        return data
    }

    @Emit("pullClose")
    pullClose(bool=false){
        return bool || false
    }

    @Prop(Boolean)
    hide!:boolean
    get getHide(){
        return this.hide
    }
    @Watch("getHide")
    changeHide(newVal:boolean){
        this.show = newVal
    }

    @Prop(Array)
    defaultDate!:any
    get getDefaultDate(){
        return this.defaultDate || []
    }
}
