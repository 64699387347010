








































import {Vue, Component} from "vue-property-decorator";
import AdminAddCouponType from "@/views/Coupon/AdminAddCoupon/AdminAddCoupon";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ChangeInput from "@/components/ShareComponent/InputVal/index.vue";
import SelTime from "@/views/Coupon/AdminAddCoupon/components/SelTime/index.vue"
// import CouponSelType from "@/views/Coupon/AdminAddCoupon/components/SelType/index.vue";
import SelItem from "@/components/ShareComponent/PullSelItem.vue"
import ZoomPage from "@/impView/PageSafety";
import {PropData, TypeList} from '../AdminCouponList/Model';
import {addCoupon, updateCoupon} from "@/views/Coupon/AdminAddCoupon/Server";
import moment from "moment";

@Component({name: "AdminAddCoupon",components:{ HeadTop,ChangeInput,SelTime,SelItem }})
export default class AdminAddCoupon extends ZoomPage implements AdminAddCouponType{
    rightIcon = require("@/assets/icon/Basics/Right.png")
    showTime = false
    TimeStr = ""
    TimeList = []
    showType = false
    TypeList = []
    TypeIndex = 0;
    upData = {
        type:"0",
        startDate:"",
        endDate:""
    }
    name = ""
    amount = ""
    desc = ""
    minAmount = ""

    mounted() {
        this.TypeList = TypeList
        PropData.forEach(item=> { this[item] = "";this.upData[item] = ""; });
    }

    activated() {
        let { state = "" } = this.$route.query
        if ( state == "update" ) this.initData();
        else{
            PropData.forEach(item=> { this[item] = "";this.upData[item] = ""; });
            this.TypeIndex = 0
            this.TimeStr = ""
            this.upData = {
                type:"0",
                startDate:"",
                endDate:""
            }
        }
    }

    initData(){
        let { data = "" } = this.$route.query
        let oldData:any = {}
        try {
            oldData = JSON.parse(data as string);
        }catch (e) {
            console.info("装换失败")
            oldData = eval(data as string);
        }
        let { startDate="",endDate="",type=0 } = oldData
        PropData.forEach(item=>{
            this[item] = oldData[item] + '' || ''
        })
        let key = TypeList.findIndex(a=>a.state == type)
        this.TypeIndex = (key >= 0) ? key : 0;
        this.TimeStr = moment(startDate).format("YYYY-MM-DD") + " / " + moment(endDate).format("YYYY-MM-DD")
        if ( startDate && endDate ) this.TimeList = [new Date(startDate),new Date(endDate)];
    }

    handleChangeInput(e){
        let { other = {},data = "" } = e;
        let { state = "" } = other;
        this[state] = data;
        this.upData[state] = data
    }

    handleClickSel(e:any){
        let { state = "" } = e;
        if ( state == "time" )
            this.showTime = !this.showTime;
        else
            this.showType = !this.showType;
    }

    handleGetType(e:any){
        let { index = 0,data = {} } = e;
        let { state = "0" } = data
        this.upData.type = state;
        this.TypeIndex = index;
    }

    handleGetClose(e:boolean){ this.showTime = e }

    handleGetConFirm(e){
        let { startDate = "",endDate = "" } = e
        this.upData.startDate = startDate
        this.upData.endDate = endDate
        this.TimeStr = startDate + " / " + endDate
    }

    handleClickBtn(){
        let { state = "" } = this.$route.query
        let { upData = {} } = this;
        if ( state == "update" ){
            let { data = "" } = this.$route.query
            let oldData = {}
            try {
                oldData = JSON.parse(data as string);
            }catch (e) {
                console.info("装换失败")
                oldData = eval(data as string);
            }
            updateCoupon(upData,oldData).then(res=>{
                this.$router.go(-1)
            })
        }else{
            addCoupon(upData).then(res=>{
                this.$router.go(-1)
            })
        }
    }

    get getTitle(){
        let { state = "" } = this.$route.query
        if ( state == "update" ){
            return "修改优惠卷"
        }else{
            return "添加优惠卷"
        }
    }

}
